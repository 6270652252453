/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginForm } from '../../models';
import store from '../../stores/store';

const Login = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm<LoginForm>();
    const [showPwd, setShowPwd] = useState(false);

    const onSubmit: SubmitHandler<LoginForm> = async(data) => {
        console.log(data);
        let btn_login = document.getElementById('btn_login') as HTMLButtonElement;
        btn_login.disabled = true;
        btn_login.innerText = 'Connexion en cours...';

        const { login } = store.auth;

        const res = await login(data);
        console.log("api res",res);
        
        if (res.success) {
            toast.success(res.message);
            setTimeout(() => {
                navigate('/dashboard');
            }, 3500);
        } else {
            toast.error(res.message);
            setTimeout(() => {
                btn_login?.removeAttribute('disabled');
            }, 3500);
            btn_login.innerText = 'Se connecter';
        }

    };

    return (
        <>
            <div className="auth-main">
                <div className="auth-wrapper v3">
                    <div className="auth-form">
                        <div className="card my-5">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body">
                                    <a href="https://solayaagency.fr/" className="d-flex justify-content-center" target='_blank' rel="noreferrer">
                                        <img src="assets/images/logo.png" alt="logo" className="logo logo-lg" width={50} />
                                    </a>
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="auth-header">
                                                <h2 className="text-secondary mt-5"><b>SOLAYA AGENCY - ADMIN</b></h2>
                                                <p className="f-16 mt-2">
                                                    Entrez vos identifiants pour vous connecter à votre espace
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="saprator mt-3">
                                        <div className="line"></div>
                                        <div className="line"></div>
                                    </div>
                                    <h5 className="my-4 d-flex justify-content-center">Entrez vos identifiants</h5>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Email address / Username"  {...register("username", { required: true })} />
                                        <label htmlFor="floatingInput">Email address / Username</label>
                                        {errors.username && <span className='text-danger'>Veuillez entrez un nom d'utilisateur</span>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type={showPwd ? 'text': 'password' } className="form-control" id="floatingInput1" placeholder="Password" {...register("password", { required: true })} />
                                        <label htmlFor="floatingInput1">Mot de passe</label>
                                        {errors.password && <span className='text-danger'>Veuillez entrez votre mot de passe</span>}
                                    </div>
                                    <div className="d-flex mt-1 justify-content-between">
                                        <div className="form-check">
                                            <input className="form-check-input input-primary" type="checkbox" id="customCheckc1" onChange={() => setShowPwd(!showPwd)} />
                                            <label className="form-check-label text-muted" htmlFor="customCheckc1">Affichez le mot de passe</label>
                                        </div>
                                        <Link to={'auth/forgot-password'}>
                                            <h5 className="text-secondary">Mot de passe oublié ?</h5>
                                        </Link>
                                    </div>
                                    <div className="d-grid mt-4">
                                        <button type="submit" id='btn_login' className="btn btn-secondary">Se connecter</button>
                                    </div>
                                    <hr />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login
