import React, { useState } from 'react'
import { Devis } from '../../models'
import { useForm } from 'react-hook-form';
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { toast } from 'react-toastify';
interface ReplyFormData {
    replyMessage: string;
    comment: string;
    price: number;
    file_doc: FileList;
}

const ProcessDevisModal = ({ devis, index }: { devis: Devis, index: number }) => {

    const [showReplyForm, setShowReplyForm] = useState(false);
    const { register, handleSubmit } = useForm<ReplyFormData>();

    const snap = useSnapshot(store.core)


    const onSubmit = async (data: ReplyFormData) => {
        const formData = new FormData();
        formData.append('replyMessage', data.replyMessage);
        formData.append('comment', data.comment);
        formData.append('price', data.price.toString());
        formData.append('devis_id', devis.id.toString())
        if (data.file_doc && data.file_doc[0]) {
            formData.append('file_doc', data.file_doc[0]);
        }
        console.log('=============submit form data=======');
        console.log(data);
        console.log('====================================');
        try {
            // Envoyer la réponse à l'aide de l'API ou effectuer toute autre action nécessaire
            const response = await snap.processedDevis(formData)
            if (response.success) {
                console.log('Réponse envoyée avec succès');
                // Réinitialiser le formulaire et masquer le formulaire de réponse
                toast.success(response.message);
                // reset();
                setShowReplyForm(false);
                setTimeout(() => {
                    window.location.reload()
                }, 3500);
            } else {
                console.error('Une erreur s\'est produite lors de l\'envoi de la réponse');
                toast.error("Erreur : " + response.message);
            }
        } catch (error) {
            console.error('Une erreur s\'est produite lors de l\'envoi de la réponse :', error);
            toast.error("Erreur technique");
        }
    };


    return (
        <>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#devisModal_${devis.id}`}>
                Traiter
            </button>
            <div className="modal fade" id={`devisModal_${devis.id}`} tabIndex={-1} aria-labelledby={`devisModalLabel_${devis.id}`} aria-hidden="true">
                <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id={`devisModalLabel_${devis.id}`}>
                                Traitement de la demande de devis pour: <b>{devis.libelle}</b>
                            </h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p><strong>Nom :</strong> {devis.customer?.nom} {devis.customer?.prenoms} </p>
                            <p><strong>Email :</strong> {devis.customer?.email}</p>
                            <p><strong>Téléphone :</strong> {devis.customer?.phone}</p>
                            <p><strong>Adresse :</strong> {devis.country}</p>
                            <p><strong>Créé le :</strong> {devis.created_at}</p>
                            <p><strong>Status: </strong> En attente de traitement</p>
                            <p><strong>Message :</strong> {devis.description}</p>

                            <hr />

                            {/* Afficher le formulaire de réponse s'il est activé */}
                            {showReplyForm && (
                                <form onSubmit={handleSubmit(onSubmit)} method='post' encType='multipart/form-data' >
                                    <div className="row">
                                        <div className="col-ld-12 col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="comment" className="form-label">Commentaire sur le devis :</label>
                                                <textarea {...register('comment')} className="form-control" id="comment" rows={3} required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-ld-6 col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="price" className='form-label'>Prix du service</label>
                                                <input type="number" className='form-control' id='price' {...register('price', { required: true })} placeholder="Veuillez entrer le prix du service à payer" />
                                            </div>
                                        </div>
                                        <div className="col-ld-6 col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="file_doc" className='form-label'>Document du service</label>
                                                <input type="file" className='form-control' id='file_doc' {...register('file_doc', { required: false })} placeholder="Uploader un document pour ce devis" />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Envoyer</button>
                                </form>
                            )}
                        </div>
                        <div className="modal-footer">
                            {/* Bouton pour activer/désactiver le formulaire de réponse */}
                            <button type="button" className="btn btn-secondary me-2" onClick={() => setShowReplyForm(!showReplyForm)}>
                                {showReplyForm ? 'Masquer le formulaire de réponse' : 'Répondre'}
                            </button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProcessDevisModal
