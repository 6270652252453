/* eslint-disable @typescript-eslint/no-unused-vars */
import { proxy } from 'valtio';
import type { User, Response, Client, Contact, Activity } from '../../models';
import { getDataWithToken,getData } from '../../helpers/http';

class UserStore {
    users: User[] = [];
    clients: Client[] = [];
    contacts: Contact[] = [];
    activities: Activity[] = [];

    async getUsers(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/users');
            if (response.success) {
                this.users = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async getClients(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/clients');
            if (response.success) {
                this.clients = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async getContacts(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/clients/contacts');
            if (response.success) {
                this.contacts = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async getActivities(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/users/activities');
            if (response.success) {
                this.activities = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

}

const userStore = proxy(new UserStore());
export default userStore;