import React from 'react'

const BreadCrumb = () => {
    return (
        <>
            {/* [ breadcrumb ] start */}
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Sample Page</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="https://berry.azurewebsites.net/navigation/index.html">Home</a>
                                </li>
                                <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                                <li className="breadcrumb-item" aria-current="page">Sample Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* [ breadcrumb ] end */}

        </>
    )
}

export default BreadCrumb
