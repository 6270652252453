import React, { useEffect } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';

const PlanPage = () => {

    const snap = useSnapshot(store);

    const fetchPlans = async () => {
        await store.core.getPlans();
        if (snap.core.plans.length > 0) {
            console.log("plans", snap.core.plans);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchPlans();
        }
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si sélection
        return () => {
            // Code de nettoyage si sélection
        }
    })

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES PLANS</h5>
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nom</th>
                                            <th>Prix (EURO) </th>
                                            <th>Offres</th>
                                            <th>Actions</th>
                                            {/* Ajoutez d'autres colonnes en fonction des propriétés de vos plans */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.core.plans.map((plan) => (
                                            <tr key={plan.id}>
                                                <td>{plan.id}</td>
                                                <td>{plan.name}</td>
                                                <td>{plan.price}</td>
                                                <td>
                                                    {plan.features?.map((feature: string) => (
                                                        <li key={feature}>{feature}</li>
                                                    ))}
                                                </td>
                                                <td>
                                                    <button className="btn btn-primary btn-sm">
                                                        <i className="fa fa-edit"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanPage
