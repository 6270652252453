import React, { useEffect } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import AddClientModal from '../../components/modals/AddClientModal';

const ClientPage = () => {

    const snap = useSnapshot(store);

    const fetchClients = async () => {
        await store.user.getClients();
        if (snap.user.clients.length > 0) {
            console.log("clients", snap.user.clients);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchClients();
        }
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si    
        return () => {
            // Code de nettoyage si    
        }
    })

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES CLIENTS</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="form-search">
                                        <i className="ti ti-search" />
                                        <input type="search" className="form-control" placeholder="Search Followers" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <AddClientModal />
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nom</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Role</th>
                                            <th>Actions</th>
                                            {/* Ajoutez d'autres colonnes en fonction des propriétés de vos clients */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.user.clients.map((client) => (
                                            <tr key={client.id}>
                                                <td>{client.id}</td>
                                                <td>{client.nom}</td>
                                                <td>{client.email}</td>
                                                <td>{client.phone}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientPage
