/* eslint-disable @typescript-eslint/no-unused-vars */
import { proxy } from 'valtio';
import type { Response, Plan, Service, Devis, Subscription, Transaction } from '../../models';
import { getDataWithToken,getData, postDataWithFile } from '../../helpers/http';

class CoreStore {
    plans: Plan[] = [];
    services: Service[] = [];
    devis: Devis[] = [];
    subscriptions: Subscription[] = [];
    transactions: Transaction[] = [];

    async getPlans(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/plans');
            if (response.success) {
                this.plans = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getServices(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/services');
            if (response.success) {
                this.services = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getDevis(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/devis');
            if (response.success) {
                this.devis = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async processedDevis(payload: any): Promise<Response> {
        try {
            const response = await postDataWithFile('admin/devis/processed', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: "Echec, une erreur interne est survenue",
                result: null,
            }
        }
    }

    async getSubscriptions(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/subscriptions');
            console.log('============api response========================');
            console.log(response.result);
            console.log('====================================');
            if (response.success) {
                this.subscriptions = response.result;
            }
            return response;
        } catch (error) {
            console.log('============error========================');
            console.log(error);
            return {
                success: false,
                message: "Echec, une erreur interne est survenue",
                result: null,
            }
        }
    }

    async getTransactions(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/transactions');
            if (response.success) {
                this.transactions = response.result;
            }
            return response;
        } catch (error) {
            console.log('============error========================');
            console.log(error);
            return {
                success: false,
                message: "Echec, une erreur interne est survenue",
                result: null,
            }
        }
    }

}

const coreStore = proxy(new CoreStore());

export default coreStore