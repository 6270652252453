import React from 'react'

const Footer = () => {

    const today = new Date();

    return (
        <>
            {/* [ Main Content ] end */}
            <footer className="pc-footer">
                <div className="footer-wrapper container-fluid">
                    <div className="row">
                        <div className="col-sm-6 my-1">
                            <p className="m-0">Solaya Agency ♥ @copyright {today.getFullYear()}  </p>
                        </div>
                        <div className="col-sm-6 ms-auto my-1">
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer
