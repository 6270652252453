import React, { useEffect } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';

const ServicesPage = () => {

    const snap = useSnapshot(store);

    const fetchServices = async () => {
        await store.core.getServices();
        if (snap.core.services.length > 0) {
            console.log("services", snap.core.services);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchServices();
        }
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si sélection
        return () => {
            // Code de nettoyage si sélection
        }
    })

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES SERVICES</h5>
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nom</th>
                                            <th>Prix (EURO) </th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                            {/* Ajoutez d'autres colonnes en fonction des propriétés de vos services */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.core.services.map((service, index) => (
                                            <tr key={service.id}>
                                                <td>{index+1}</td>
                                                <td>{service.libelle}</td>
                                                <td>{service.price}</td>
                                                <td>{service.description}</td>
                                                <td>
                                                    {/* <ServiceModal service={service} index={service.id} /> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesPage
