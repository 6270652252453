import React, { useState } from 'react';
import { Contact } from '../../models';
import { useForm } from 'react-hook-form';

interface ReplyFormData {
    replyMessage: string;
}

const ContactModal = ({ contact,index }: { contact: Contact, index: number }) => {
    const [showReplyForm, setShowReplyForm] = useState(false);
    const { register, handleSubmit } = useForm<ReplyFormData>();

    const onSubmit = (data: ReplyFormData) => {
        // Envoyer la réponse à l'aide de l'API ou effectuer toute autre action nécessaire
        console.log('Réponse envoyée :', data.replyMessage);
        // Réinitialiser le formulaire et masquer le formulaire de réponse
        // reset();
        setShowReplyForm(false);
    };

    return (
        <>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#contactModal_${contact.id}`}>
                Voir les détails
            </button>
            <div className="modal fade" id={`contactModal_${contact.id}`} tabIndex={-1} aria-labelledby={`contactModalLabel_${contact.id}`} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={`contactModalLabel_${contact.id}`}>{contact.subject}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p><strong>Nom :</strong> {contact.name}</p>
                            <p><strong>Email :</strong> {contact.email}</p>
                            <p><strong>Téléphone :</strong> {contact.phone}</p>
                            <p><strong>Adresse :</strong> {contact.address}</p>
                            <p><strong>Créé le :</strong> {contact.created_at}</p>
                            <p><strong>Message :</strong> {contact.message}</p>

                            {/* Afficher le formulaire de réponse s'il est activé */}
                            {showReplyForm && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mb-3">
                                        <label htmlFor="replyMessage" className="form-label">Répondre :</label>
                                        <textarea {...register('replyMessage')} className="form-control" id="replyMessage" rows={3} required></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Envoyer</button>
                                </form>
                            )}
                        </div>
                        <div className="modal-footer">
                            {/* Bouton pour activer/désactiver le formulaire de réponse */}
                            <button type="button" className="btn btn-secondary me-2" onClick={() => setShowReplyForm(!showReplyForm)}>
                                {showReplyForm ? 'Masquer le formulaire de réponse' : 'Répondre'}
                            </button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactModal;
