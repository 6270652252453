/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import ContactModal from '../../components/modals/ContactModal';

const ContactPage = () => {
    const snap = useSnapshot(store);

    const fetchContacts = async () => {
        await store.user.getContacts();
        if (snap.user.contacts.length > 0) {
            console.log("contacts", snap.user.contacts);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchContacts();
        }
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si sélection
        return () => {
            // Code de nettoyage si sélection
        }
    })

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                {/* [ sample-page ] start */}
                <div className="col-sm-12">
                <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES CONTACTS</h5>
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {/* <th>ID</th> */}
                                            <th>Nom</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Objet</th>
                                            <th>Actions</th>
                                            {/* Ajoutez d'autres colonnes en fonction des propriétés de vos clients */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.user.contacts.map((contact) => (
                                            <tr key={contact.id}>
                                                {/* <td>{contact.id}</td> */}
                                                <td>{contact.name}</td>
                                                <td>{contact.email}</td>
                                                <td>{contact.phone}</td>
                                                <td>{contact.subject}</td>
                                                <td>
                                                    <ContactModal contact={contact} index={contact.id}  />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* [ sample-page ] end */}
            </div>

        </div>
    )
}

export default ContactPage
