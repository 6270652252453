import React from 'react'

const Dashboard = () => {
    return (
        <>
            {/* [ Main Content ] start */}
            <div className="row">
                {/* [ sample-page ] start */}
                <div className="col-xl-4 col-md-6">
                    <div className="card bg-warning dashnum-card text-white overflow-hidden">
                        <span className="round small" />
                        <span className="round big" />
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="avtar avtar-lg">
                                        <i className="text-white ti ti-credit-card" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    
                                </div>
                            </div>
                            <span className="text-white d-block f-34 f-w-500 my-2">$1350 <i className="ti ti-arrow-up-right-circle opacity-50" /></span>
                            <p className="mb-0 opacity-50">Total Pending Orders</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="card bg-dark dashnum-card text-white overflow-hidden">
                        <span className="round small" />
                        <span className="round big" />
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="avtar avtar-lg">
                                        <i className="text-white ti ti-credit-card" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <span className="text-white d-block f-34 f-w-500 my-2">$1305 <i className="ti ti-arrow-up-right-circle opacity-50" /></span>
                                </div>
                                <div className="col-6">
                                    {/* <div id="tab-chart-1" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-12">
                    <div className="card bg-dark dashnum-card dashnum-card-small text-white overflow-hidden">
                        <span className="round bg-primary small" />
                        <span className="round bg-primary big" />
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                                <div className="avtar avtar-lg">
                                    <i className="text-white ti ti-credit-card" />
                                </div>
                                <div className="ms-2">
                                    <h4 className="text-white mb-1">$203k <i className="ti ti-arrow-up-right-circle opacity-50" /></h4>
                                    <p className="mb-0 opacity-50 text-sm">Net Profit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card dashnum-card dashnum-card-small overflow-hidden">
                        <span className="round bg-warning small" />
                        <span className="round bg-warning big" />
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                                <div className="avtar avtar-lg bg-light-warning">
                                    <i className="text-warning ti ti-credit-card" />
                                </div>
                                <div className="ms-2">
                                    <h4 className="mb-1">$550K <i className="ti ti-arrow-up-right-circle opacity-50" /></h4>
                                    <p className="mb-0 opacity-50 text-sm">Total Revenue</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3 align-items-center">
                                <div className="col">
                                    <small>Total Growth</small>
                                    <h3>$2,324.00</h3>
                                </div>
                                <div className="col-auto">
                                    <select className="form-select p-r-35">
                                        <option>Today</option>
                                        <option selected>This Month</option>
                                        <option>This Year</option>
                                    </select>
                                </div>
                            </div>
                            <div id="growthchart" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3 align-items-center">
                                <div className="col">
                                    <h4>Popular Stocks</h4>
                                </div>
                                <div className="col-auto"> </div>
                            </div>
                            <div className="rounded bg-light-secondary overflow-hidden mb-3">
                                <div className="px-3 pt-3">
                                    <div className="row mb-1 align-items-start">
                                        <div className="col">
                                            <h5 className="text-secondary mb-0">Bajaj Finery</h5>
                                            <small className="text-muted">10% Profit</small>
                                        </div>
                                        <div className="col-auto">
                                            <h4 className="mb-0">$1839.00</h4>
                                        </div>
                                    </div>
                                </div>
                                <div id="bajajchart" />
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-0">
                                    <div className="row align-items-start">
                                        <div className="col">
                                            <h5 className="mb-0">Bajaj Finery</h5>
                                            <small className="text-success">10% Profit</small>
                                        </div>
                                        <div className="col-auto">
                                            <h4 className="mb-0">$1839.00<span className="ms-2 align-top avtar avtar-xxs bg-light-success"><i className="ti ti-chevron-up text-success" /></span></h4>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item px-0">
                                    <div className="row align-items-start">
                                        <div className="col">
                                            <h5 className="mb-0">TTML</h5>
                                            <small className="text-danger">10% Profit</small>
                                        </div>
                                        <div className="col-auto">
                                            <h4 className="mb-0">$100.00<span className="ms-2 align-top avtar avtar-xxs bg-light-danger"><i className="ti ti-chevron-down text-danger" /></span></h4>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item px-0">
                                    <div className="row align-items-start">
                                        <div className="col">
                                            <h5 className="mb-0">Reliance</h5>
                                            <small className="text-success">10% Profit</small>
                                        </div>
                                        <div className="col-auto">
                                            <h4 className="mb-0">$200.00<span className="ms-2 align-top avtar avtar-xxs bg-light-success"><i className="ti ti-chevron-up text-success" /></span></h4>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item px-0">
                                    <div className="row align-items-start">
                                        <div className="col">
                                            <h5 className="mb-0">TTML</h5>
                                            <small className="text-danger">10% Profit</small>
                                        </div>
                                        <div className="col-auto">
                                            <h4 className="mb-0">$189.00<span className="ms-2 align-top avtar avtar-xxs bg-light-danger"><i className="ti ti-chevron-down text-danger" /></span></h4>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item px-0">
                                    <div className="row align-items-start">
                                        <div className="col">
                                            <h5 className="mb-0">Stolon</h5>
                                            <small className="text-danger">10% Profit</small>
                                        </div>
                                        <div className="col-auto">
                                            <h4 className="mb-0">$189.00<span className="ms-2 align-top avtar avtar-xxs bg-light-danger"><i className="ti ti-chevron-down text-danger" /></span></h4>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="text-center">
                                <a href="#!" className="b-b-primary text-primary">View all <i className="ti ti-chevron-right" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* [ sample-page ] end */}
            </div>
            {/* [ Main Content ] end */}

        </>
    )
}

export default Dashboard
