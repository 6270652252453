/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

const Topbar = () => {
    const navigate = useNavigate();
    const onLogout = () => {
        localStorage.clear();
        toast.success("Vous avez bien été déconnecté avec succès");
        setTimeout(() => {
            navigate('/');
        }, 3500);
    }

    return (
        <>
            {/* [ Header Topbar ] start */}
            <header className="pc-header">
                <div className="header-wrapper"> {/* [Mobile Media Block] start */}
                    <div className="me-auto pc-mob-drp">
                        <ul className="list-unstyled">
                            <li className="pc-h-item header-mobile-collapse">
                                <a href="#" className="pc-head-link head-link-secondary ms-0" id="sidebar-hide">
                                    <i className="ti ti-menu-2" />
                                </a>
                            </li>
                            <li className="pc-h-item pc-sidebar-popup">
                                <a href="#" className="pc-head-link head-link-secondary ms-0" id="mobile-collapse">
                                    <i className="ti ti-menu-2" />
                                </a>
                            </li>
                            <li className="dropdown pc-h-item d-inline-flex d-md-none">
                                <a className="pc-head-link head-link-secondary dropdown-toggle arrow-none m-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="ti ti-search" />
                                </a>
                                <div className="dropdown-menu pc-h-dropdown drp-search">
                                    <form className="px-3">
                                        <div className="form-group mb-0 d-flex align-items-center">
                                            <i data-feather="search" />
                                            <input type="search" className="form-control border-0 shadow-none" placeholder="Search here. . ." />
                                        </div>
                                    </form>
                                </div>
                            </li>
                            <li className="pc-h-item d-none d-md-inline-flex">
                                <form className="header-search">
                                    <i data-feather="search" className="icon-search" />
                                    <input type="search" className="form-control" placeholder="Search here. . ." />
                                    <button className="btn btn-light-secondary btn-search"><i className="ti ti-adjustments-horizontal" /></button>
                                </form>
                            </li>
                        </ul>
                    </div>
                    {/* [Mobile Media Block end] */}
                    <div className="ms-auto">
                        <ul className="list-unstyled">
                            <li className="dropdown pc-h-item pc-mega-menu">
                                {/* <a className="pc-head-link head-link-secondary dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="ti ti-access-point" />
                                </a> */}
                                {/* <div className="dropdown-menu pc-h-dropdown pc-mega-dmenu">
                                    <div className="row g-0">
                                        <div className="col text-center image-block">
                                            <img src="https://berry.azurewebsites.net/assets/images/pages/img-megamenu.svg" alt="image" className="img-fluid rounded" />
                                        </div>
                                        <div className="col">
                                            <h6 className="mega-title">UI Components</h6>
                                            <ul className="pc-mega-list">
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Alerts</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Accordions</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Avatars</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Badges</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Breadcrumbs</a></li>
                                            </ul>
                                        </div>
                                        <div className="col">
                                            <h6 className="mega-title">UI Components</h6>
                                            <ul className="pc-mega-list">
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Menus</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Modals</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Pagination</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Search Bar</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Tabs</a></li>
                                            </ul>
                                        </div>
                                        <div className="col">
                                            <h6 className="mega-title">Advance Components</h6>
                                            <ul className="pc-mega-list">
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Advanced Stats</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Advanced Cards</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Lightbox</a></li>
                                                <li><a href="#!" className="dropdown-item"><i className="fas fa-circle" /> Notification</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </li>
                            <li className="dropdown pc-h-item">
                                <a className="pc-head-link head-link-primary dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="ti ti-language" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end pc-h-dropdown">
                                    <a href="#!" className="dropdown-item">
                                        <span>English <small>(UK)</small></span>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <span>français <small>(French)</small></span>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <span>Română <small>(Romanian)</small></span>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <span>中国人 <small>(Chinese)</small></span>
                                    </a>
                                </div>
                            </li>
                            <li className="dropdown pc-h-item">
                                <a className="pc-head-link head-link-secondary dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="ti ti-bell" />
                                </a>
                                <div className="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown">
                                    <div className="dropdown-header">
                                        <a href="#!" className="link-primary float-end text-decoration-underline">Mark as all read</a>
                                        <h5>All Notification <span className="badge bg-warning rounded-pill ms-1">01</span></h5>
                                    </div>
                                    <div className="dropdown-header px-0 text-wrap header-notification-scroll position-relative" style={{ maxHeight: 'calc(100vh - 215px)' }}>
                                        <div className="list-group list-group-flush w-100">
                                            <div className="list-group-item">
                                                <select className="form-select">
                                                    <option value="all">All Notification</option>
                                                    <option value="new">New</option>
                                                    <option value="unread">Unread</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                            <div className="list-group-item list-group-item-action">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src="../assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-1">
                                                        <span className="float-end text-muted">2 min ago</span>
                                                        <h5>John Doe</h5>
                                                        <p className="text-body fs-6">It is a long established fact that a reader will be distracted </p>
                                                        <div className="badge rounded-pill bg-light-danger">Unread</div>
                                                        <div className="badge rounded-pill bg-light-warning">New</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list-group-item list-group-item-action">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <div className="user-avtar bg-light-success"><i className="ti ti-building-store" /></div>
                                                    </div>
                                                    <div className="flex-grow-1 ms-1">
                                                        <span className="float-end text-muted">3 min ago</span>
                                                        <h5>Store Verification Done</h5>
                                                        <p className="text-body fs-6">We have successfully received your request.</p>
                                                        <div className="badge rounded-pill bg-light-danger">Unread</div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="text-center py-2">
                                        <a href="#!" className="link-primary">Mark as all read</a>
                                    </div>
                                </div>
                            </li>
                            <li className="dropdown pc-h-item header-user-profile">
                                <a className="pc-head-link head-link-primary dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <img src="../assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar" />
                                    <span>
                                        <i className="ti ti-settings" />
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown">
                                    <div className="dropdown-header">
                                        <h4>Good Morning, <span className="small text-muted"> John Doe</span></h4>
                                        <p className="text-muted">Project Admin</p>
                                        <hr />
                                        <div className="profile-notification-scroll position-relative" style={{ maxHeight: 'calc(100vh - 280px)' }}>
                                            
                                            <a href="../ApplicationUser/AccountProfile01.html" className="dropdown-item">
                                                <i className="ti ti-settings" />
                                                <span>Account Settings</span>
                                            </a>
                                            <a href="../ApplicationUser/SocialProfile.html" className="dropdown-item">
                                                <i className="ti ti-user" />
                                                <span>Social Profile</span>
                                            </a>
                                            <a href="#" className="dropdown-item" onClick={() => onLogout()}>
                                                <i className="ti ti-logout" />
                                                <span>Logout</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            {/* [ Header ] end */}

        </>
    )
}

export default Topbar
