import { proxy } from 'valtio';
import authStore from './auth/authStore';
import userStore from './admin/userStore';
import coreStore from './admin/coreStore';
const store = proxy({
    auth: authStore,
    user: userStore,
    core: coreStore
});

export default store;