/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import BreadCrumb from '../../components/layouts/BreadCrumb';
import store from '../../stores/store';
import { useSnapshot } from 'valtio';

const UsersPage = () => {
    const snap = useSnapshot(store);
    const { users } = snap.user;

    const fetchUsers = async () => {
        await store.user.getUsers();
        if (users.length > 0) {
            console.log("users", users);            
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchUsers();
        };
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si nécessaire
        return () => {
            // Code de nettoyage si nécessaire
        };
    }, []);

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES UTILISATEURS</h5>
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nom</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Role</th>
                                        <th>Actions</th>
                                        {/* Ajoutez d'autres colonnes en fonction des propriétés de vos utilisateurs */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user) => (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phone}</td>
                                            <td>{user.role?.name}</td>
                                            <td>
                                                <button className="btn btn-primary btn-sm">
                                                    <div className="fa fa-edit"></div>
                                                </button>
                                                <button className="btn btn-danger btn-sm">
                                                    <div className="fa fa-trash"></div>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsersPage;
