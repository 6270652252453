import React from 'react';
import {Outlet} from 'react-router-dom';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import Footer from './footer/Footer';

const MainLayout = () => {
    return (
        <>
            <Sidebar />
            <Topbar />
            <div className='pc-container'>
                <div className="pc-content">
                    
                    {/* [ Main Content ] start */}
                    <div className="row">
                        <Outlet />
                    </div>
                    {/* [ Main Content ] end */}
                </div>
            </div>
            <Footer />

        </>
    )
}

export default MainLayout
