import React, { useEffect } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { getDateFormat } from '../../helpers/utils';

const ActivityPage = () => {

    const snap = useSnapshot(store);

    const fetchActivities = async () => {
        await store.user.getActivities();
        if (snap.user.activities.length > 0) {
            console.log("activities", snap.user.activities);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchActivities();
        }
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si sélection
        return () => {
            // Code de nettoyage si sélection
        }
    })

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">HISTORIQUES DE CONNEXION</h5>
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Utilisateur</th>
                                            <th>Date</th>
                                            <th>IP</th>
                                            <th>Device</th>
                                            <th>Activité</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.user.activities.map((activity, index) => (
                                            <tr key={activity.id}>
                                                <td>{index+1}</td>
                                                <td>{activity.user?.name}</td>
                                                <td>{getDateFormat(activity.created_at)}</td>
                                                <td>{activity.ip_address}</td>
                                                <td>{activity.user_agent?.slice(0,50)} </td>
                                                <td>{activity.activity}</td>
                                                <td>{activity.description}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActivityPage
