import React from 'react'

const AddClientModal = () => {
    return (
        <>
            <button className="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvas_add_contact">
                <i className="ti ti-circle-plus align-text-bottom" /> Nouveau Client
            </button>
            {/* [ Main Content ] end */}
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvas_add_contact">
                <div className="offcanvas-body">
                    {/* <div className="media align-items-center">
                        <div className="avtar avtar-l bg-light-primary text-primary rounded-circle"><i className="fas fa-user" /> </div>
                        <div className="media-body mx-3">
                            <button className="btn btn-outline-primary btn-sm mb-1"><i className="ti ti-upload" /> Upload</button>
                            <span className="text-muted text-sm d-block">Image size should be 125kb Max.</span>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div> */}
                    <h4 className="my-3">Ajouter un nouveau client</h4>
                    <hr className="my-3" />
                    <div className="form-group">
                        <label className="form-label">Nom</label>
                        <div className="input-group">
                            <span className="input-group-text"><i className="material-icons-two-tone">account_circle</i></span>
                            <input type="text" className="form-control" placeholder="Enter Name" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Prenoms</label>
                        <div className="input-group">
                            <span className="input-group-text"><i className="material-icons-two-tone">account_circle</i></span>
                            <input type="text" className="form-control" placeholder="Enter Name" />
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label className="form-label">Profession</label>
                        <div className="input-group">
                            <span className="input-group-text"><i className="material-icons-two-tone">work</i></span>
                            <input type="text" className="form-control" placeholder="Enter Job Title" />
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label className="form-label">Email</label>
                        <div className="input-group">
                            <span className="input-group-text"><i className="material-icons-two-tone">email</i></span>
                            <input type="text" className="form-control" placeholder="Enter Email" />
                        </div>
                    </div>
                        
                    <div className="form-group">
                        <label className="form-label">Téléphone</label>
                        <div className="input-group">
                            <span className="input-group-text"><i className="material-icons-two-tone">call</i></span>
                            <input type="text" className="form-control" placeholder="Enter Phone Number" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Pays</label>
                        <select className="form-control" name="country_id" id="country_id" >
                            <option value="" defaultValue={''}>Choisir un pays</option>
                            <option value="TOGO">TOGO</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Ville</label>
                        <div className="input-group">
                            <span className="input-group-text"><i className="material-icons-two-tone">location_on</i></span>
                            <input type="text" className="form-control" placeholder="Entrer la ville du client" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Civilité</label>
                        <select className="form-control" name="gender" id="gender" >
                            <option value="MASCULIN" defaultValue={'MASCULIN'}>MASCULIN</option>
                            <option value="FEMININ">FEMININ</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Situation Matrimoniale</label>
                        <select className="form-control" name="situation_matrimoniale" id="situation_matrimoniale" >
                            <option value="Célibataire" defaultValue={'Célibataire'}>Célibataire</option>
                            <option value="Marié(e)">Marié(e)</option>
                            <option value="Divorce(e)">Divorce(e)</option>
                            <option value="Veuf(ve)">Veuf(ve)</option>
                        </select>
                    </div>
                    
                    <div className="row">
                        <div className="col-6"><div className="d-grid"><button className="btn btn-primary">Save</button></div></div>
                        <div className="col-6"><div className="d-grid"><button className="btn btn-outline-primary" data-bs-dismiss="offcanvas">Cancel</button></div></div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddClientModal
