import React from 'react';
import AppRoutes from './AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {
	RecoilRoot,
} from 'recoil';
function App() {
  return (
    <div className="app">
			<RecoilRoot>
				<AppRoutes />
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
			</RecoilRoot>
		</div>
  );
}

export default App;
